<style scoped>
.marquee {
	display: flex;
	overflow: hidden;
	user-select: none;
	gap: calc(clamp(10rem, 30rem + 120vmin, 30rem) / 16);
	mask-image: linear-gradient(
			to right,
			hsl(0 0% 0% / 0),
			hsl(0 0% 0% / 1) 20%,
			hsl(0 0% 0% / 1) 80%,
			hsl(0 0% 0% / 0)
	);
}

.marquee__group {
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	gap: calc(clamp(10rem, 30rem + 120vmin, 30rem) / 16);
	min-width: 100%;
	animation: scroll-x 25s linear infinite;
}

.marquee--reverse .marquee__group {
	animation-direction: reverse;
	animation-delay: -3s;
}

@keyframes scroll-x {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-100% - calc(clamp(10rem, 30rem + 120vmin, 30rem) / 17)));
	}
}
@keyframes fade {
	to {
		opacity: 0;
		visibility: hidden;
	}
}
</style>
<script>
import { Carousel, Slide } from 'vue-carousel';
//import CountryFlag from 'vue-country-flag';
export default {
  name: 'Testimonials',
  components: {
    Carousel,
    Slide,
	  //CountryFlag
  }
}
</script>

<template>
  <div class="fun-fact-area ptb-100 bg-color mt-0 pt-0">
    <div class="container">
      <div class="section-title">
        <h2>Unsere Kunden</h2>
      </div>
    </div>

    <carousel
        :autoplay="true"
        :loop="true"
        :autoplayTimeout="7000"
        :speed="1000"
        :paginationEnabled="true"
        :perPageCustom="[[0, 1], [768, 2], [1200, 3], [1550, 4]]"
        :centerMode="true"
        class="feedback-slides owl-carousel owl-theme"
    >
			<!--
	    <slide style="min-width: 500px;">
		    <div class="single-feedback-item" style="height: 12.5rem !important;">
			    <p>
				    “Unser Softwareprojekt war ein voller Erfolg. Danke an das Team!”
				    <br desc="Virtual padding"/>
				    <br desc="Virtual padding"/>
			    </p>

			    <div class="client-info" style="padding-left: 0rem;">
				    <h3>Sandro Bisaro</h3>
				    <span class="d-inline-block">
					    CRS Management & Service AG
					  </span>
			    </div>
		    </div>
	    </slide>
			-->

	    <slide style="min-width: 500px;">
		    <div class="single-feedback-item" style="height: 12.5rem !important;">
			    <p>“Top-tier solutions and guidance. Their expertise and commitment are second to none.”</p>

			    <div class="client-info" style="padding-left: 0rem;">
				    <h3>Josh Morris <span class="d-inline-block small">of kaisan.cloud</span></h3>
				    <span class="d-inline-block">
					    Network Diagnostics Ltd.
					  </span>
			    </div>
		    </div>
	    </slide>

	    <slide style="min-width: 500px;">
		    <div class="single-feedback-item" style="height: 12.5rem !important;">
			    <p>“Thrilled with the expertise and support. They turn challenges into remarkable solutions effortlessly!”</p>

			    <div class="client-info" style="padding-left: 0rem;">
				    <h3>Dennis Stanoev</h3>
				    <span class="d-inline-block">
					    Digital Grotto Ltd.
					  </span>
			    </div>
		    </div>
	    </slide>

	    <slide style="min-width: 500px;">
		    <div class="single-feedback-item" style="height: 12.5rem !important;">
			    <p>“Wir sind absolut begeistert! Das Team erfüllt unsere Erwartungen in jeder Hinsicht.”</p>

			    <div class="client-info" style="padding-left: 0rem;">
				    <h3>Wolfgang Scheiblauer</h3>
				    <span class="d-inline-block">
					    meineWeideGans & BESONDERS*S E-Commerce
					  </span>
			    </div>
		    </div>
	    </slide>
    </carousel>
	  <div class="wrapper mt-4 bg-light pt-5 pb-5">
		  <div class="marquee">
			  <div class="marquee__group">
				  <img src="../../assets/images/customers/kaisan.webp" alt="kaisan.cloud">
				  <img src="../../assets/images/customers/digitalgrotto.png" alt="Digital Grotto">
				  <img src="../../assets/images/customers/onetriq.svg" alt="OneTriQ" style="height: 64px;">
				  <img src="../../assets/images/customers/ayedo.png" alt="Ayedo" style="height: 64px;">
				  <img src="../../assets/images/customers/avemio.tech.svg" alt="Avemio.Tech" style="height: 64px;">
				  <img src="../../assets/images/customers/loopback.png" alt="Loopback.Cloud" style="height: 64px;">
				  <img src="../../assets/images/customers/mwg.png" alt="MeineWeideGans" style="height: 64px;">
				  <img src="../../assets/images/customers/besonderss.png" alt="Besonderss" style="height: 64px;">
				  <!-- <img src="../../assets/images/customers/crs.png" alt="CRS" style="height: 64px;"> -->
			  </div>

			  <div aria-hidden="true" class="marquee__group">
				  <img src="../../assets/images/customers/kaisan.webp" alt="kaisan.cloud">
				  <img src="../../assets/images/customers/digitalgrotto.png" alt="Digital Grotto">
				  <img src="../../assets/images/customers/onetriq.svg" alt="OneTriQ" style="height: 64px;">
				  <img src="../../assets/images/customers/ayedo.png" alt="Ayedo" style="height: 64px;">
				  <img src="../../assets/images/customers/avemio.tech.svg" alt="Avemio.Tech" style="height: 64px;">
				  <img src="../../assets/images/customers/loopback.png" alt="Loopback.Cloud" style="height: 64px;">
				  <img src="../../assets/images/customers/mwg.png" alt="MeineWeideGans" style="height: 64px;">
				  <img src="../../assets/images/customers/besonderss.png" alt="Besonderss" style="height: 64px;">
				  <!-- <img src="../../assets/images/customers/crs.png" alt="CRS" style="height: 64px;"> -->
			  </div>
		  </div>
	  </div>
  </div>
</template>
