<template>
    <div class="featured-services-area pt-100 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Dienstleistungen</span>
                <h2>Unsere Leistungen</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-featured-box">
                        <div class="icon">
                            <i class="flaticon-research"></i>
                        </div>

                        <h3>Consulting</h3>
                        <p>
                          Beratung für Ihr Software oder Cloud Projekt
                        </p>

                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-featured-box">
                        <div class="icon color-facd60">
                            <i class="flaticon-architecture"></i>
                        </div>

                        <h3>Development</h3>
                        <p>Entwicklung von individuellen Softwarelösungen</p>

                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-featured-box">
                        <div class="icon color-1ac0c6">
                            <i class="flaticon-data"></i>
                        </div>

                        <h3>Operations</h3>
                        <p>Betrieb von IT Infrastruktur</p>

                    </div>
                </div>
            </div>

		        <div class="row">
			        <div class="col-lg-4 col-sm-6 col-md-6">
				        <div class="single-featured-box">
					        <div class="icon">
						        <i class="flaticon-shopping-cart"></i>
					        </div>

					        <h3>Adaptive Payments</h3>
					        <p>
						        Lösungen für Ihre Abrechnung und Buchhaltung
					        </p>
				        </div>
			        </div>

			        <div class="col-lg-4 col-sm-6 col-md-6">
				        <div class="single-featured-box">
					        <div class="icon color-facd60">
						        <i class="flaticon-digital-marketing"></i>
					        </div>

					        <h3>Cloud Native</h3>
					        <p>Hoch skalierbar. Immer erreichbar. Einfach Cloud.</p>

				        </div>
			        </div>

			        <div class="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-md-3 offset-sm-3">
				        <div class="single-featured-box">
					        <div class="icon color-1ac0c6">
						        <i class="flaticon-email"></i>
					        </div>

					        <h3>Transactional Mail</h3>
					        <p>Mailing Services und Infrastruktur</p>

				        </div>
			        </div>
		        </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OurFeaturedServices',
    }
</script>