<template>
    <div class="hero-banner banner-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="hero-main-banner-content">
                                <span class="sub-title">Consulting, Development, Operations</span>
                                <h1>Intelligente Lösungen für Ihr Unternehmen</h1>
                                <p>Von Beratung, über die Entwicklung bis zum Betrieb. CFTools ist Ihr Ansprechpartner rund um die Themen Software und Cloud.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="hero-main-banner-image-cf">


                                <img src="../../assets/images/banner/main-pic.png" alt="main-pic">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape-img1"><img src="../../assets/images/shape/shape-1.png" alt="image"></div>
        <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
        <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
        <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
        <div class="shape-img5"><img src="../../assets/images/shape/shape-5.png" alt="image"></div>
        <div class="shape-img6"><img src="../../assets/images/shape/shape-6.png" alt="image"></div>
        <div class="shape-img7"><img src="../../assets/images/shape/shape-7.png" alt="image"></div>
        <div class="shape-img8"><img src="../../assets/images/shape/shape-8.png" alt="image"></div>
        <div class="shape-img9"><img src="../../assets/images/shape/shape-9.png" alt="image"></div>
        <div class="shape-img10"><img src="../../assets/images/shape/shape-10.png" alt="image"></div>
    </div>
</template>

<script>
    export default {
        name: 'MainBannerThree'
    }
</script>