<template>
    <div class="subscribe-area bg-F4F7FC">
        <div class="container-fluid p-0">
            <div class="subscribe-inner-area">
                <div class="subscribe-content">
                    <span class="sub-title">Interesse geweckt?</span>
                    <h2>Treten Sie mit uns in Kontakt</h2>

                    <div class="row mt-2 justify-content-center">
                      <div class="col-2">
                        <router-link to="/contact">
                          <button class="default-btn">
                            Los gehts<span></span>
                          </button>
                        </router-link>
                      </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape-img2"><img src="../../assets/images/shape/shape-2.svg" alt="image"></div>
        <div class="shape-img3"><img src="../../assets/images/shape/shape-3.svg" alt="image"></div>
        <div class="shape-img4"><img src="../../assets/images/shape/shape-4.png" alt="image"></div>
        <div class="shape-img5"><img src="../../assets/images/shape/shape-5.png" alt="image"></div>
        <div class="shape-img6"><img src="../../assets/images/shape/shape-6.png" alt="image"></div>
    </div>
</template>

<script>
    export default {
        name: 'GetOnlyNewUpdate'
    }
</script>