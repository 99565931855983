<template>
    <div>
        <Navbar />
        <MainBannerThree />
        <OurFeaturedServices class="pt-40" />
        <!-- <FunFacts /> -->
        <Testimonials/>
        <CallToAction/>
        <Footer />
    </div>
</template>

<script>
import Navbar from '../components/Layout/Navbar'
import MainBannerThree from '../active-components/Home/Banner'
import OurFeaturedServices from '../active-components/Home/Services'

//import FunFacts from '../active-components/Home/Facts'

import Testimonials  from '../active-components/Home/Testimonials'

import CallToAction from '../active-components/Home/CallToAction'

import Footer from '../components/Layout/Footer'

export default {
    components: { 
        Navbar,
        MainBannerThree,
        OurFeaturedServices,

        //FunFacts,

        Testimonials,

        CallToAction,

        Footer
    }
}
</script>